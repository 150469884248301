import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';   
import TopBarLoder from './TopBarLoder'; 
import { GoogleLogout } from 'react-google-login';

const Header =(props)=> {  
 

    let history = useHistory();
    const handleLogout = ()=>{
        localStorage.removeItem('all_users');
        props.setUserlogin(false);
        props.setNotification({title: "", description: "", type: ""});  
        history.push('/login');
    } 

    return (
        <> 


        <header className="main-header header-style-two">
            <TopBarLoder progress={props.progress}/> 
            <div className="header-upper">
                <div className="outer-container clearfix">
                    
                    <div className="pull-left logo-box">
                        <div className="logo"><Link to="/"><img src={process.env.PUBLIC_URL + '/assets/images/logo-2.png'} alt="" title=""/></Link></div>
                    </div>
                    
                    <div className="nav-outer clearfix"> 
                        <div className="mobile-nav-toggler"><span className="icon flaticon-menu"></span></div>
                        <nav className="main-menu navbar-expand-md">
                            <div className="navbar-header">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                            
                            <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                <ul className="navigation clearfix">
                                    <li><Link to="/">Home</Link></li>  
                                    <li><Link to="/courses">Courses</Link></li>  
                                    <li><Link to="/portfolio">Modern Portfolio</Link></li> 
                                    <li><Link to="/contact-us">Contact Us</Link></li> 
                                </ul>
                            </div>
                        </nav>
                         
                        <div className="outer-box clearfix"> 

                            {!props.userlogin?<>
                            <Link to="/login" className="btn btn-success mr-2" type="button">Login</Link>
                            <Link to="/sign-up" className="btn btn-warning" type="button">Sign Up</Link>
                            </>:<>
                            <Link to="/my-profile" className="btn btn-success mr-2" type="button">My Profile</Link>
                            <GoogleLogout
                            clientId="1085691765967-buplq754pv8j4v7foe0tfs7iat2vq79i.apps.googleusercontent.com"
                            buttonText="Logout"
                            render={renderProps => (
                                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-warning" type="button">Logout</button>
                              )}
                            onLogoutSuccess={handleLogout}
                            >
                            </GoogleLogout>
                            </>}
                          
                          
                            <div style={{display:'none'}} className="cart-box">
                                <div className="dropdown">
                                    <button className="cart-box-btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="flaticon-shopping-bag-1"></span><span className="total-cart">2</span></button>
                                    <div className="dropdown-menu pull-right cart-panel" aria-labelledby="dropdownMenu1">

                                        <div className="cart-product">
                                            <div className="inner">
                                                <div className="cross-icon"><span className="icon fa fa-remove"></span></div>
                                                <div className="image"><img src={process.env.PUBLIC_URL + '/assets/images/resource/post-thumb-1.jpg'} alt="" /></div>
                                                <h3><a href="shop-single.html">Product 01</a></h3>
                                                <div className="quantity-text">Quantity 1</div>
                                                <div className="price">$99.00</div>
                                            </div>
                                        </div>
                                        <div className="cart-product">
                                            <div className="inner">
                                                <div className="cross-icon"><span className="icon fa fa-remove"></span></div>
                                                <div className="image"><img src={process.env.PUBLIC_URL + '/assets/images/resource/post-thumb-2.jpg'} alt="" /></div>
                                                <h3><a href="shop-single.html">Product 02</a></h3>
                                                <div className="quantity-text">Quantity 1</div>
                                                <div className="price">$99.00</div>
                                            </div>
                                        </div>
                                        <div className="cart-total">Sub Total: <span>$198</span></div>
                                        <ul className="btns-boxed">
                                            <li><a href="shoping-cart.html">View Cart</a></li>
                                            <li><a href="checkout.html">CheckOut</a></li>
                                        </ul>

                                    </div>
                                </div>
                            </div> 
                                
                        </div>
                    </div>
                    
                </div>
            </div> 
             
            <div className="sticky-header">
                <div className="auto-container clearfix"> 
                    <div className="logo pull-left">
                        <Link to="/" title=""><img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="" title=""/></Link>
                    </div> 
                    <div className="pull-right"> 
                        <nav className="main-menu"> 
                        </nav> 
                        <div className="outer-box clearfix">
                            
                        </div>
                        
                    </div>
                </div>
            </div> 

            <div className="mobile-menu">
                <div className="menu-backdrop"></div>
                <div className="close-btn"><span className="icon flaticon-multiply"></span></div>
                
                <nav className="menu-box">
                    <div className="nav-logo"><Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="" title=""/></Link></div>
                    <div className="menu-outer"></div>
                </nav>
            </div> 
        
        </header>              
        </>
    ) 
}

export default Header;