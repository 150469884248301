import React, { useEffect } from 'react';  
import { Link } from 'react-router-dom';    
import Helmet from 'react-helmet';
 
const Home=(props)=> {  
  
    useEffect(() => { 
        props.setProgress(100);  
    });   

    return (
        <> 

        <Helmet>
            <title>The Future is Being Built Now</title>
            <meta name="description" content="Providing computer and laptop services, Smart Techno is considered as the best shop in Raisar Plaza" />
            <meta name="keywords" content="computerstoreindia,computer store india, smart techno jaipur" />
        </Helmet>    

        <section className="banner-section-two">
            <div className="auto-container">
                <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/background/pattern-15.png)"}}></div>
                <div className="pattern-layer-three" style={{backgroundImage:"url(assets/images/main-slider/icon-2.png)"}}></div>
                <div className="row clearfix">
                 
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="pattern-layer-one" style={{backgroundImage:"url(assets/images/main-slider/pattern-1.png)"}}></div>
                        <div className="icon-layer-one" style={{backgroundImage:"url(assets/images/main-slider/icon-2.png)"}}></div>
                        <div className="icon-layer-two" style={{backgroundImage:"url(assets/images/main-slider/pattern-2.png)"}}></div>
                        <div className="icon-layer-three" style={{backgroundImage:"url(assets/images/main-slider/icon-1.png)"}}></div>
                        <div className="icon-layer-four" style={{backgroundImage:"url(assets/images/icons/icon-3.png)"}}></div>
                        <div className="icon-layer-five" style={{backgroundImage:"url(assets/images/icons/icon-4.png)"}}></div>
                        <div className="inner-column">
                            <div className="title">Learn latest skills</div>
                            <h1>The Future <br/>  is Being Built Now, <br/> Are You Ready?</h1>
                            <div className="btns-box">
                                <Link to="#" className="theme-btn btn-style-one"><span className="txt">Join Now!</span></Link>
                            </div>
                        </div>
                    </div>
                     
                    <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="image titlt" data-tilt data-tilt-max="4">
                                <img src="assets/images/main-slider/image-5.png" alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> 

        <section className="instructor-section">
            <div className="background-layer" style={{backgroundImage:"url(assets/images/background/1.png)"}}></div>
            <div className="background-layer-one" style={{backgroundImage:"url(assets/images/background/pattern-1.png)"}}></div>
            <div className="background-layer-two" style={{backgroundImage:"url(assets/images/background/pattern-2.png)"}}></div>
            <div className="auto-container">
                <div className="row clearfix">
                     
                    <div className="blocks-column col-lg-8 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="row clearfix">
                                 
                                <div className="service-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="border-layer"></div>
                                        <div className="icon-box">
                                            <div className="icon flaticon-verify"></div>
                                        </div>
                                        <h4><Link to="#">Live Trading Experience</Link></h4>
                                        <div className="text">We make sure that trader is well equipped with trading system & different strategies.</div>
                                    </div>
                                </div>
                                 
                                <div className="service-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInLeft" data-wow-delay="150ms" data-wow-duration="1500ms">
                                        <div className="border-layer"></div>
                                        <div className="icon-box">
                                            <div className="icon flaticon-heart-box"></div>
                                        </div>
                                        <h4><Link to="#">Life Time Membership</Link></h4>
                                        <div className="text">After enrolling with us, you’ll get multiple takes for the same course.</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                     
                    <div className="instructor-column col-lg-4 col-md-12 col-sm-12">
                        <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <h4>Open Free* Demat Account with Angel One</h4>
                            <p>Investments in Equity, Currency, Futures & Options, Commodities</p> 
                            <Link className="click-here" to="#">Click here for apply</Link>
                            <div className="image titlt" data-tilt data-tilt-max="4">
                                <img src="assets/images/resource/instructor.png" alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> 

        <section className="professional-section">
            <div className="background-layer-one" style={{backgroundImage:"url(assets/images/background/pattern-5.png)"}}></div>
            <div className="auto-container">
                <div className="row clearfix">
                     
                    <div className="images-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="pattern-layer" style={{backgroundImage:"url(assets/images/background/pattern-3.png)"}}></div>
                            <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/background/pattern-4.png)"}}></div>
                            <div className="color-layer"></div>
                            <div className="color-layer-two"></div>
                            <div className="image wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <img src="assets/images/resource/professional.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                     
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="pattern-layer-three" style={{backgroundImage:"url(assets/images/background/pattern-16.png)"}}></div>
                            <div className="sec-title">
                                <div className="title">Learn anything</div>
                                <h2>About Our Trainer</h2>
                            </div> 
                            <div style={{textAlign:'justify'}} className="bold-text">Shatabdi Saxena is BSE Certified Trainer & Investment Advisor and founder of ' Rise High Investment Consultants '
where she & her Team is committed to providing training on the Stock Market, Mutual Funds, Futures & Options via Virtual & Offline mode along with customized investment solutions.
Achieved more than 10 years of quality experience in the Financial Market. She was also associated with esteem organisations like Sherkhan and ICICI Securities. 
</div>
                            <div className="btn-box">
                                <Link to={'courses'} className="theme-btn btn-style-four"><span className="txt">Short courses</span></Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section> 




        <section className="feature-section">
            <div className="pattern-layer" style={{backgroundImage:"url(assets/images/background/pattern-7.png)"}}></div>
            <div className="auto-container">
                <div className="row clearfix">
                     
                    <div className="content-column col-lg-7 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <div className="title">Explore featured courses</div>
                                <h2>BSE Certification </h2>
                                <div className="text">One of the only financial advisory firms in Rajasthan to offer BSE certification courses. Train with our experienced and qualified experts today.</div>
                            </div>
                            <div className="btn-box">
                                <Link to="courses" className="theme-btn btn-style-two"><span className="txt">Short courses</span></Link>
                            </div>
                        </div>
                    </div>
                     
                    <div className="image-column col-lg-5 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="color-layer"></div>
                            <div className="image wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{visibility:'visible', animationDuration:'1500ms',animationDelay:"0ms",animationName: 'fadeInLeft'}}>
                                <img src="assets/images/resource/feature.jpg" alt=""/>
                                <div className="overlay-box">
                                    <div className="content">
                                        
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section>



        <section className="feature-section-two">
            <div className="background-layer-one" style={{backgroundImage:"url(assets/images/background/pattern-2.png)"}}></div>
            <div className="circle-one"></div>
            <div className="circle-two"></div>
            <div className="auto-container">
                <div className="sec-title centered">
                    <h2>Best combination we have for you!</h2>
                 
                </div>
                <div className="row clearfix">
                     
                  <div className="course-block style-two col-lg-3 col-md-6 col-sm-12">
                        <div className="inner-box">
                            <div className="image">
                                <Link to="#"><img src="assets/images/resource/course-4.jpg" alt="" /></Link>
                                <div className="tag">ENROL NOW</div>
                            </div>
                            <div className="lower-content">
                                <h4><Link to="#">Essence Of Financial Market</Link></h4>
                                <div className="uni-name">Rise High</div>
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span>
                                    <strong>4.9</strong>
                                    <i>(70 Review)</i>
                                </div>
                                <div className="clearfix">
                                    <div className="price">₹ 1500 <span>₹ 2500</span></div>
                                    <div className="hovers">2.0 total hours . All Levels</div>
                                </div>
                            </div>
                             
                            <div className="overlay-content-box">
                                <h4>Essence Of Financial Market</h4>
                                <div className="uni-name">Rise High</div>
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span>
                                    <strong>4.9</strong>
                                    <i>(70 Review)</i>
                                </div>
                                <div className="clearfix">
                                    <div className="price">₹ 1500 <span>₹ 2500</span></div>
                                    <div className="hovers">2.0 total hours . All Levels</div>
                                </div>
                                <div className="text">Link between savings and investment that meet the short-term and long-term financial needs.</div>
                                <ul className="lists">
                                    <li>BOSM</li>
                                    <li>Personal Financial Management</li>
                                </ul>
                                <div className="btns-box">
                                    <Link to="#" className="theme-btn enrol-btn">Enrol Now</Link>
                                    <Link to="#" className="theme-btn wishlist-btn">Add to wishlist</Link>
                                </div>
                            </div> 
                            
                        </div>
                    </div>
                     
                    <div className="course-block style-two col-lg-3 col-md-6 col-sm-12">
                        <div className="inner-box">
                            <div className="image">
                                <Link to="#"><img src="assets/images/resource/course-4.jpg" alt="" /></Link>
                                <div className="tag">ENROL NOW</div>
                            </div>
                            <div className="lower-content">
                                <h4><Link to="#">Trading For Treasury</Link></h4>
                                <div className="uni-name">Rise High</div>
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span>
                                    <strong>4.9</strong>
                                    <i>(70 Review)</i>
                                </div>
                                <div className="clearfix">
                                    <div className="price">₹ 15000 <span>₹ 25000</span></div>
                                    <div className="hovers">24.00 total hours . All Levels</div>
                                </div>
                            </div>
                             
                            <div className="overlay-content-box">
                                <h4>Trading For Treasury</h4>
                                <div className="uni-name">Rise High</div>
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span>
                                    <strong>4.9</strong>
                                    <i>(70 Review)</i>
                                </div>
                                <div className="clearfix">
                                    <div className="price">₹ 15000 <span>₹ 25000</span></div>
                                    <div className="hovers">24.00 total hours . All Levels</div>
                                </div>
                                <div className="text">Trading discipline employed to evaluate investments and identify trading opportunities.</div>
                                <ul className="lists">
                                    <li>Types Of Charts</li>
                                    <li>Trend Analysis</li>
                                    <li>Types Of Candles</li>
                                </ul>
                                <div className="btns-box">
                                    <Link to="#" className="theme-btn enrol-btn">Enrol Now</Link>
                                    <Link to="#" className="theme-btn wishlist-btn">Add to wishlist</Link>
                                </div>
                            </div> 
                            
                        </div>
                    </div>
                     
                    <div className="course-block style-two col-lg-3 col-md-6 col-sm-12">
                        <div className="inner-box">
                            <div className="image">
                                <Link to="#"><img src="assets/images/resource/course-4.jpg" alt="" /></Link>
                                <div className="tag">ENROL NOW</div>
                            </div>
                            <div className="lower-content">
                                <h4><Link to="#">Mastering The Market</Link></h4>
                                <div className="uni-name">Rise High</div>
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span>
                                    <strong>4.9</strong>
                                    <i>(70 Review)</i>
                                </div>
                                <div className="clearfix">
                                    <div className="price">₹ 25000 <span>₹ 35000</span></div>
                                    <div className="hovers">40 total hours . All Levels</div>
                                </div>
                            </div>
                             
                            <div className="overlay-content-box">
                                <h4>Mastering The Market</h4>
                                <div className="uni-name">Rise High</div>
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span>
                                    <strong>4.9</strong>
                                    <i>(70 Review)</i>
                                </div>
                                <div className="clearfix">
                                    <div className="price">₹ 25000 <span>₹ 35000</span></div>
                                    <div className="hovers">40 total hours . All Levels</div>
                                </div>
                                <div className="text">Trading discipline employed to evaluate investments and identify trading opportunities.</div>
                                <ul className="lists">
                                    <li>Types Of Charts</li>
                                    <li>Trend Analysis</li>
                                    <li>Types Of Candles</li>
                                </ul>
                                <div className="btns-box">
                                    <Link to="#" className="theme-btn enrol-btn">Enrol Now</Link>
                                    <Link to="#" className="theme-btn wishlist-btn">Add to wishlist</Link>
                                </div>
                            </div> 
                            
                        </div>
                    </div>
                     
                    <div className="course-block style-two col-lg-3 col-md-6 col-sm-12">
                        <div className="inner-box">
                            <div className="image">
                                <Link to="#"><img src="assets/images/resource/course-4.jpg" alt="" /></Link>
                                <div className="tag">ENROL NOW</div>
                            </div>
                            <div className="lower-content">
                                <h4><Link to="#">Mutual Fund Best Hai</Link></h4>
                                <div className="uni-name">Rise High</div>
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span>
                                    <strong>4.9</strong>
                                    <i>(70 Review)</i>
                                </div>
                                <div className="clearfix">
                                    <div className="price">₹ 5000 <span>₹ 7500</span></div>
                                    <div className="hovers">12.00 total hours . All Levels</div>
                                </div>
                            </div>
                             
                            <div className="overlay-content-box">
                                <h4>Mutual Fund Best Hai</h4>
                                <div className="uni-name">Rise High</div>
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span>
                                    <strong>4.9</strong>
                                    <i>(70 Review)</i>
                                </div>
                                <div className="clearfix">
                                    <div className="price">₹ 5000 <span>₹ 7500</span></div>
                                    <div className="hovers">12.00 total hours . All Levels</div>
                                </div>
                                <div className="text">Trading discipline employed to evaluate investments and identify trading opportunities.</div>
                                <ul className="lists">
                                    <li>Types Of Charts</li>
                                    <li>Trend Analysis</li>
                                    <li>Types Of Candles</li>
                                </ul>
                                <div className="btns-box">
                                    <Link to="#" className="theme-btn enrol-btn">Enrol Now</Link>
                                    <Link to="#" className="theme-btn wishlist-btn">Add to wishlist</Link>
                                </div>
                            </div> 
                            
                        </div>
                    </div> 
                    
                </div>
            </div>
        </section> 

        <section className="event-section">
            <div className="auto-container">
                <div className="row clearfix">
                     
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <div className="title">Explore Event</div>
                                <h2>Our Upcoming Events</h2>
                            </div>
                            <div className="bold-text">Sed do eiusmod but laboris incididunt ut labore et dolore magna aliqua.sed do eiusmod</div>
                            <p>aboris incididunt ut labore et dolore magna aliqua.sed do eiusmod but laboris incididunt ut labore et dolore magna aliqua.but laboris incididunt ut labore et dolore magna aliqua.</p>
                             <div className="btn-box">
                                <Link to="events" className="theme-btn btn-style-one"><span className="txt">Explore Event</span></Link>
                            </div>
                        </div>
                    </div>
                     
                    <div className="images-column col-lg-6 col-md-12 col-sm-12">
                        <div className="background-layer-one" style={{backgroundImage:"url(assets/images/background/pattern-7.png)"}}></div>
                        <div className="inner-column titlt" data-tilt data-tilt-max="4">
                            <div className="image wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <img src="assets/images/resource/event.png" alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section> 

        <section className="testimonial-section-two style-two">
            <div className="circle-one paroller" data-paroller-factor="-0.20" data-paroller-factor-lg="0.20" data-paroller-type="foreground" data-paroller-direction="horizontal"></div>
            <div className="circle-two paroller" data-paroller-factor="0.20" data-paroller-factor-lg="-0.20" data-paroller-type="foreground" data-paroller-direction="horizontal"></div>
            <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/background/pattern-10.png)"}}></div>
            <div className="auto-container">
                <div className="inner-container">
                    <div className="pattern-layer" style={{backgroundImage:"url(assets/images/background/pattern-9.png)"}}></div>
                    <div className="sec-title centered">
                        <div className="title">Testimonial</div>
                        <h2>Words From Customers</h2>
                     </div>

                    <div className="testimonial-carousel owl-carousel owl-theme">
                     
                        <div className="testimonial-block-two">
                            <div className="inner-box">
                                <div className="border-layer"></div>
                                <div className="text text-limit">Mastering the Market on Fundamental & Technical Analysis is a very good program to gain basic understanding and grounding in stock markets trading. It is equally useful for both beginners as well as advanced traders looking for advanced knowledge and insights
Ms. Shatabdi takes a lot of effort in training beginners to make the right choices when it comes to investments. With intraday trading as we are expected to buy and sell shares on the same day, there was a lot of trading anxiety we used to face in the initial days. This trading learning program proved super useful, and we have gained confidence in ourselves, which in turn helped us to trade more positively.
</div>
                                <div className="author-info">
                                    <div className="info-inner">
                                        <div className="author-image">
                                            <img src="assets/images/resource/author-1.jpg" alt="" />
                                        </div>
                                        <h6>Alok Sharma</h6>
                                        <div className="designation">Online Teacher</div>
                                    </div>
                                </div>
                                <div className="quote-icon flaticon-quote-2"></div>
                            </div>
                        </div>
                         
                        <div className="testimonial-block-two">
                            <div className="inner-box">
                                <div className="border-layer"></div>
                                <div className="text text-limit">The training conducted by Ms. Shatabdi Saxena was exactly what I was looking for. Genuinely enjoyed each class. The content was simple, informative and precise.
Ms. Saxena has structured the course very well and, I feel she has had to put in a lot of effort in designing the contents of each class. She was open to questions and discussions. Whenever any participant had a doubt or query, she would ensure it was answered to his / her satisfaction, and did not hesitate to move away from the subject of the day, if that was required. All subjects covered were supported with examples.
Ms. Saxena, you have done a wonderful job and I appreciate your efforts. I have no hesitation in recommending this training to people I know.

P.S. Course fees already recovered through trades
</div>
                                <div className="author-info">
                                    <div className="info-inner">
                                        <div className="author-image">
                                            <img src="assets/images/resource/author-2.jpg" alt="" />
                                        </div>
                                        <h6>Sunil Talreja</h6>
                                        <div className="designation">Online Teacher</div>
                                    </div>
                                </div>
                                <div className="quote-icon flaticon-quote-2"></div>
                            </div>
                        </div>
                         
                        <div className="testimonial-block-two">
                            <div className="inner-box">
                                <div className="border-layer"></div>
                                <div className="text text-limit">Thank you for providing such wonderful sessions on Technical analysis on stock trading.

These sessions on technical analysis for stock trading have proven to be a great introduction of Stock market and trading to me. Before the sessions, I was a naive person who had no idea as to how to practice trading in stocks. These sessions have built up my basic knowledge to start trading and have boosted my confidence. All the strategies taught during sessions have been of great value.

 I loved the teaching method and the depth of understanding provided on each topic as well.
</div>
                                <div className="author-info">
                                    <div className="info-inner">
                                        <div className="author-image">
                                            <img src="assets/images/resource/author-3.jpg" alt="" />
                                        </div>
                                        <h6>BARKHA KHANDELWAL</h6>
                                        <div className="designation">Online Teacher</div>
                                    </div>
                                </div>
                                <div className="quote-icon flaticon-quote-2"></div>
                            </div>
                        </div>
                         
                        
                    
                    </div>


                </div>
            </div>
        </section>
         
        <section style={{display:'none'}} className="goal-section">
            <div className="pattern-layer-four" style={{backgroundImage:"url(assets/images/background/pattern-18.png)"}}></div>
            <div className="auto-container">
                <div className="row clearfix">
                     
                    <div className="images-column col-lg-6 col-md-12 col-sm-12">
                        <div className="color-layer"></div>
                        <div className="inner-column">
                            <div className="pattern-layer" style={{backgroundImage:"url(assets/images/background/pattern-4.png)"}}></div>
                            <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/background/pattern-17.png)"}}></div>
                            <div className="image">
                                <img src="assets/images/resource/goal-1.jpg" alt="" />
                            </div>
                            <div className="image-two">
                                <img src="assets/images/resource/goal-2.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                     
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="pattern-layer-three" style={{backgroundImage:"url(assets/images/background/pattern-13.png)"}}></div>
                            <div className="sec-title">
                                <div className="title">Achieve Goals</div>
                                <h2>Start To Success</h2>
                                <div className="text">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur Duis aute irure dolor in</div>
                            </div>
                             
                            <div className="fact-counter">
                                <div className="row clearfix">
 
                                    <div className="column counter-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="count-outer count-box">
                                                    <span className="count-text" data-speed="3000" data-stop="36">0</span>+
                                                </div>
                                                <h4 className="counter-title">Over 35 Free Courses</h4>
                                            </div>
                                        </div>
                                    </div>
 
                                    <div className="column counter-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="count-outer count-box alternate">
                                                    <span className="count-text" data-speed="5000" data-stop="34">0</span>
                                                </div>
                                                <h4 className="counter-title">Member State Associations</h4>
                                            </div>
                                        </div>
                                    </div>
 
                                    <div className="column counter-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="count-outer count-box">
                                                    <span className="count-text" data-speed="2000" data-stop="15">0</span>k
                                                </div>
                                                <h4 className="counter-title">Over 19,000 High Schools</h4>
                                            </div>
                                        </div>
                                    </div>
 
                                    <div className="column counter-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                            <div className="content">
                                                <div className="count-outer count-box">
                                                    <span className="count-text" data-speed="3500" data-stop="20">0</span>m
                                                </div>
                                                <h4 className="counter-title">Over 11 million Students</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section> 

        <section className="skill-section">
            <div className="pattern-layer" style={{backgroundImage:"url(assets/images/background/pattern-8.png)"}}></div>
            <div className="auto-container">
                <div className="row clearfix">
                     
                    <div className="skill-column col-lg-7 col-md-12 col-sm-12">
                        <div className="inner-column"> 
                            <div className="sec-title">
                                <div className="title">Rise High</div>
                                <h2>Investment Skills</h2>
                                <div className="text">"An investment in knowledge pays the best interest." — Benjamin Franklin</div>
                            </div>
                             
                            <div className="skills">

 
                                <div className="skill-item">
                                    <div className="skill-header clearfix">
                                        <div className="skill-title">Analytical Skill</div>
                                        <div className="skill-percentage">
                                            <div className="count-box">
                                                <span className="count-text" data-speed="2000" data-stop="90">0</span>%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skill-bar">
                                        <div className="bar-inner"><div className="bar progress-line" data-width="90"></div></div>
                                    </div>
                                </div>

                                <div className="skill-item">
                                    <div className="skill-header clearfix">
                                        <div className="skill-title">Focus</div>
                                        <div className="skill-percentage"><div className="count-box"><span className="count-text" data-speed="2000" data-stop="100">0</span>%</div></div>
                                    </div>
                                    <div className="skill-bar">
                                        <div className="bar-inner"><div className="bar progress-line" data-width="100"></div></div>
                                    </div>
                                </div>

 
                                <div className="skill-item">
                                    <div className="skill-header clearfix">
                                        <div className="skill-title">Research </div>
                                        <div className="skill-percentage"><div className="count-box"><span className="count-text" data-speed="2000" data-stop="95">0</span>%</div></div>
                                    </div>
                                    <div className="skill-bar">
                                        <div className="bar-inner"><div className="bar progress-line" data-width="95"></div></div>
                                    </div>
                                </div>
 


                            </div>
                            
                        </div>
                    </div>
                     
                    <div className="image-column col-lg-5 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="circle-one"></div>
                            <div className="circle-two"></div>
                            <div className="image titlt" data-tilt data-tilt-max="4">
                                <img src="assets/images/resource/skill.png" alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> 

        

        <section className="clients-section">
            <div className="auto-container"> 
                <div className="sec-title centered">
                    <h2>Key supporters</h2>
                    <div className="text">These leading universities are currently offering online degree <br/> courses on FutureLearn:</div>
                </div>

                <div className="sponsors-outer"> 
                    <ul className="sponsors-carousel owl-carousel owl-theme">
                        <li className="slide-item"><figure className="image-box"><Link to="#"><img src="assets/images/clients/1.jpeg" alt=""/></Link></figure></li>
                        <li className="slide-item"><figure className="image-box"><Link to="#"><img src="assets/images/clients/2.jpeg" alt=""/></Link></figure></li>
                        <li className="slide-item"><figure className="image-box"><Link to="#"><img src="assets/images/clients/3.jpeg" alt=""/></Link></figure></li>
                        <li className="slide-item"><figure className="image-box"><Link to="#"><img src="assets/images/clients/4.jpeg" alt=""/></Link></figure></li>
                        <li className="slide-item"><figure className="image-box"><Link to="#"><img src="assets/images/clients/5.jpeg" alt=""/></Link></figure></li>
                    </ul>
			    </div> 
                
            </div>
        </section> 

        <section className="contact-section">
            <div className="pattern-layer" style={{backgroundImage:"url(assets/images/background/pattern-19.png)"}}></div>
            <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/background/pattern-20.png)"}}></div>
            <div className="icon-layer-two" style={{backgroundImage:"url(assets/images/icons/icon-2.png)"}}></div>
            <div className="icon-layer-three" style={{backgroundImage:"url(assets/images/icons/icon-2.png)"}}></div>
            <div className="auto-container">
                <div className="row clearfix">
                     
                    <div className="info-column col-lg-4 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="icon-layer" style={{backgroundImage:"url(assets/images/icons/icon-2.png)"}}></div>
                            <div className="image">
                                <img src="assets/images/resource/contact.png" alt="" />
                            </div>
                            <h5>Contact us</h5>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page </p>
                            <ul className="contact-list">
                                <li>
                                    <strong>Phone:</strong>
                                    <a href="tel:+919166654142">+91-9166654142</a>
                                </li>
                                <li>
                                    <strong>Email:</strong>
                                    <a href="mailto:risehighco@gmail.com">risehighco@gmail.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                     
                    <div className="form-column col-lg-8 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="color-layer"></div> 
                            <div className="sec-title">
                                <h2> Visit us <br/> for Free Resources</h2>
                                <div className="text">These leading universities are currently offering online degree courses on FutureLearn:</div>
                            </div>
                             
                            <div className="default-form">
                                <form method="post" action="https://themazine.com/html/Lebari_new/lebari/lebari/contact.html">
                                    <div className="row clearfix">
                                     
                                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                            <input type="text" name="username" placeholder="First Name" required/>
                                        </div>
                                         
                                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                            <input type="text" name="lastname" placeholder="Last Name" required/>
                                        </div>
                                         
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <input type="email" name="email" placeholder="Email" required/>
                                        </div>
                                         
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <select name="country" className="custom-select-box">
                                                <option>When you want to start courses</option>
                                                <option>courses 01</option>
                                                <option>courses 02</option>
                                                <option>courses 03</option>
                                                <option>courses 04</option>
                                            </select>
                                        </div>
                                         
                                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                            <input type="text" name="date" placeholder="Date" required/>
                                        </div>
                                         
                                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                            <input type="text" name="time"  placeholder="Time" required/>
                                        </div>
                                         
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <textarea placeholder="Type you comment here"></textarea>
                                        </div>
                                        
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <button type="submit" className="theme-btn btn-style-one"><span className="txt">Submit</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>                                                           
            
        </>
    ) 
}



export default Home;