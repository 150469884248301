import React, { useState } from "react"; 
import { useForm } from "react-hook-form";  

const ContactForm = (props) => {   

    const [status, setStatus] = useState("Send Message");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
       
    const onSubmit = async (form,e) => {  

      e.preventDefault();
      setStatus("Sending...");  

      const { f_name, email, phone, con_message } = e.target.elements;
      let details = {
        name: f_name.value, 
        email: email.value,
        mobile: phone.value,
        message: con_message.value,
      };

      //this.props.setProgress(60); 

      let response = await fetch(process.env.REACT_APP_API_ADMIN_URL+"api/contactsubmit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });

      // this.props.setProgress(90); 

      let result = await response.json();
      if(result.success){
        setStatus("Sent Successfully");
        props.setNotification({title: result.message, description: "", type: "success"}); 
        e.target.reset();
      } else {
        setStatus("Send Message");
        props.setNotification({title: result.message, description: "", type: "danger"});  
      } 

      // this.props.setProgress(100);
      //alert(result.success); 

    }; 

    return (
        <> 



            <div className="contact-form">
                <form method="post" onSubmit={handleSubmit(onSubmit)} id="contact-form">
                    
                    <div className="form-group">
                        <input {...register('f_name', { required: true })} className={"input-form " + (errors.f_name ? 'form-validation-cls' : '')} type="text" name="f_name" id="f_name" placeholder="Name"/>
                    </div> 
                    
                    <div className="form-group">
                         <input {...register('email', { required: true })} className={"input-form " + (errors.email ? 'form-validation-cls' : '')} type="email" name="email" id="email" placeholder="Email Address"/>
                    </div>
                    
                    <div className="form-group">
                        <input {...register('phone', { required: false })} className={"input-form " + (errors.phone ? 'form-validation-cls' : '')} type="text" name="phone" id="phone" placeholder="Phone Number"/>
                    </div>

                    <div className="form-group">
                        <textarea {...register('con_message', { required: true })} className={"input-form " + (errors.con_message ? 'form-validation-cls' : '')} name="con_message" id="con_message" placeholder="Write Message"></textarea>
                    </div>

                    <div className="form-group">
                        <button className="common_btn theme-btn btn-style-five" type="submit" id="con_submit"><span>{status}</span></button>
                    </div>
                    
                </form>
                
            </div>
 
        </>
    );
};
  
export default ContactForm;