import React, {useState} from 'react';  

const ScrollButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (
	<div id="backToTop" onClick={scrollToTop} className="scroll-to-top scroll-to-target" data-target="html"><span className={"fa fa-angle-double-up " + (visible ? 'showit' : '')}></span></div>
);
}
export default ScrollButton;