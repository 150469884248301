import React from 'react'; 
import { Link } from 'react-router-dom';
import ScrollButton from './ScrollButton';

const Footer=()=> { 
    return (
        <>
        <footer className="main-footer">
            <div className="circle-layer"></div>
            <div className="pattern-layer-one" style={{backgroundImage:"url(assets/images/background/pattern-12.png)"}}></div>
            <div className="pattern-layer-two" style={{backgroundImage: "url(assets/images/background/pattern-13.png)"}}></div>
            <div className="pattern-layer-three" style={{backgroundImage: "url(assets/images/background/pattern-14.png)"}}></div>
            <div className="pattern-layer-four" style={{backgroundImage: "url(assets/images/background/pattern-13.png)"}}></div>
            <div className="auto-container"> 
                <div className="widgets-section">
                    <div className="row clearfix">
                         
                        <div className="footer-column col-lg-5 col-md-12 col-sm-12">
                            <div className="footer-widget logo-widget">
                                <div className="logo">
                                    <Link to="/"><img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="logo" /></Link>
                                </div>
                                <ul className="info-list">
                                    <li>Tel:<a href="tel:+919166654142"> +91-9166654142</a></li>
                                    <li>Email:<a href="mailto:risehighco@gmail.com"> risehighco@gmail.com</a></li>
                                </ul>                                 
                                <ul className="social-box">
                                    <li className="twitter"><Link to="#" className="fa fa-twitter"></Link></li>
                                    <li className="pinterest"><Link to="#" className="fa fa-pinterest-p"></Link></li>
                                    <li className="facebook"><Link to="#" className="fa fa-facebook-f"></Link></li>
                                    <li className="dribbble"><Link to="#" className="fa fa-dribbble"></Link></li>
                                </ul>
                                <div className="text">We are providing an unconventional method of learning stock market courses which will help you to become proficient in the financial world. We also deal in Mutual Fund Advisory and Broking Services</div>
                            </div>
                        </div>
                         
                        <div className="footer-column col-lg-7 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                
                                <div className="column col-lg-4 col-md-4 col-sm-12">
                                    <h5>About</h5>
                                    <ul className="list">
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="#">News</Link></li>
                                        <li><Link to="#">Impact</Link></li>
                                        <li><Link to="#">Our team</Link></li>
                                        <li><Link to="#">Our interns</Link></li>
                                    </ul>
                                </div> 
                                <div className="column col-lg-4 col-md-4 col-sm-12">
                                    <h5>Need some help?</h5>
                                    <ul className="list">
                                        <li><Link to="#">FAQs</Link></li>
                                        <li><Link to="#">Child safety</Link></li>
                                        <li><Link to="#">Help Centre</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li> 
                                    </ul>
                                </div> 
                                <div className="column col-lg-4 col-md-4 col-sm-12">
                                    <h5>Courses</h5>
                                    <ul className="list">
                                        <li><Link to="#">Khan Kids app</Link></li>
                                        <li><Link to="#">Science & engineering</Link></li>
                                        <li><Link to="#">Computing</Link></li>
                                        <li><Link to="#">Arts & humanities</Link></li>
                                        <li><Link to="#">Economics & finance</Link></li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div> 
                  
                <div className="footer-bottom">
                    <div className="row clearfix">
                         
                        <div className="copyright-column col-lg-6 col-md-12 col-sm-12">
                            <div className="copyright">Copyright 2022, All Right Reserved</div>
                        </div>
                         
                        <div className="nav-column col-lg-6 col-md-12 col-sm-12">
                            <ul>
                                <li><Link to="#">SiteMap</Link></li>
                                <li><Link to="#">Privacy Policy</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </footer>

        <ScrollButton/>

        </>
    ) 
}
export default Footer;