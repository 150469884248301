import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm'

const ContactUs=(props)=> { 

    useEffect(() => { 
        props.setProgress(100);  
    }); 


    return (
        <>

            <Helmet>
                <title data-react-helmet="true">Contact Us | Risehighco</title>
                <meta data-react-helmet="true" name="description" content="Address : F-82 First Floor, Raisar Plaza, Jaipur - 302001 | Phone - 9799974142" />
                <meta data-react-helmet="true" name="keywords" content="computerstoreindia,computer store india, smart techno jaipur" />
            </Helmet>

            <section className="contact-banner-section">
                <div className="pattern-layer-one" style={{backgroundImage:"url(assets/images/icons/icon-5.png)"}}></div>
                <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/icons/icon-6.png)"}}></div>
                <div className="pattern-layer-three" style={{backgroundImage:"url(assets/images/icons/icon-4.png)"}}></div>
                <div className="auto-container"> 
                    <ul className="page-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Contact Us</li>
                    </ul>
                    <div className="content-box">
                        <h2>Contact Now</h2>
                        <div className="text">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur Duis aute irure dolor in reprehenderit in </div>
                    </div>
                </div>
            </section>  

            <section className="contact-page-section">
                <div className="pattern-layer-three" style={{backgroundImage:"url(assets/images/icons/icon-8.png)"}}></div>
                <div className="auto-container">
                    <div className="row clearfix">
                         
                        <div className="info-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="title">GET IN TOUCH</div>
                                <h2>Visit one of our agency locations <br/> or contact us today</h2>
                                <div className="text">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, </div>
                                <ul>
                                    <li><span>Address</span>Jaipur Rajasthan India</li>
                                    <li><span>Our Phone</span>Mobile : +91-9166654142</li>
                                    <li><span>Our Email</span>Email : risehighco@gmail.com</li>
                                    <li><span>Opening Hours</span>Monday to Friday: 9.00am to 5.00pm</li>
                                </ul>
                            </div>
                        </div>
                         
                        <div className="form-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="circle-layer"></div>
                                <div className="pattern-layer-one" style={{backgroundImage:"url(assets/images/icons/icon-7.png)"}}></div>
                                <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/icons/icon-9.png)"}}></div>
                                <h2>Leave a message</h2>
                                <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut <br/> blandit arcu in pretium.</div>
                                 
                                <ContactForm setNotification={props.setNotification}/> 
                                    
                            </div>
                                 
                        </div>
                        
                    </div>
                </div>
            </section> 
            
            

            <section className="map-contact-section">
                <div className="auto-container"> 
                    <div className="map-boxed"> 
                        <div className="map-outer">
                            <iframe title="Contact Us" scrolling="no" width="100%" height="500px" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Jaipur%20Rajasthan%20India+(Risehighco)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" >&nbsp;</iframe> 
                        </div>
                    </div>
                </div>
            </section> 
            
            
        
        </>
    ) 
}
export default ContactUs;