import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'

const Courses = (props) => {

    const [course, setCourse] = useState([])


    useEffect((props) => {
        // document.title = `${capitalizeFirstLetter(props.category)} - NewsMonkey`;
        courseList(); 
        // eslint-disable-next-line
    }, [])


    const courseList = async ()=> {

        props.setProgress(10);
        
        const url = process.env.REACT_APP_API_ADMIN_URL+"api/course/list";
        //const url = `https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=${props.apiKey}&page=${page}&pageSize=${props.pageSize}`; 
        //setLoading(true)
        let data = await fetch(url);
        props.setProgress(30);
        let parsedData = await data.json()
        //console.log(parsedData.data.data);
        props.setProgress(70);
        setCourse(parsedData.data.data)
        //setTotalResults(parsedData.totalResults)
        //setLoading(false)
        props.setProgress(100);
    }

    return (

        <>

        <Helmet>
            <title>Events | Risehighco</title>
            <meta name="description" content="Smart Techno with over 9 Years of expertise in IT industry is one of the Most trusted Stores in Jaipur." />
            <meta name="keywords" content="nline Smart Techno, computer parts" />
        </Helmet>

        <section className="banner-section-three style-two">
            <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/background/pattern-21.png)"}}></div>
            <div className="auto-container"> 
                <ul className="page-breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Courses</li>
                </ul>
                <div className="row clearfix">
                     
                    <div className="content-column col-lg-8 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="pattern-layer-one" style={{backgroundImage:"url(assets/images/main-slider/pattern-1.png)"}}></div>
                            <div className="icon-layer" style={{backgroundImage:"url(assets/images/icons/icon-2.png)"}}></div>
                            <div className="icon-layer-two" style={{backgroundImage:"url(assets/images/icons/icon-5.png)"}}></div>
                            <h2> Explore <br/> Featured Courses</h2>
                        </div>
                    </div>
                     
                    <div className="image-column col-lg-4 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="icon-layer-three" style={{backgroundImage:"url(assets/images/icons/icon-3.png)"}}></div>
                            <div className="icon-layer-four" style={{backgroundImage:"url(assets/images/icons/icon-2.png)"}}></div>
                            <div className="icon-layer-five" style={{backgroundImage:"url(assets/images/icons/icon-4.png)"}}></div>
                            <div className="image">
                                <img src="assets/images/resource/page-title-3.jpg" alt="" />
                            </div>
                            <div className="image-two">
                                <img src="assets/images/resource/page-title-4.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>


        <section className="courses-page-section style-two">
            <div className="pattern-layer" style={{backgroundImage:"url(assets/images/background/pattern-22.png)"}}></div>
            <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/background/pattern-6.png)"}}></div>
            <div className="pattern-layer-three" style={{backgroundImage:"url(assets/images/background/pattern-19.png)"}}></div>
            <div className="pattern-layer-four" style={{backgroundImage:"url(assets/images/background/pattern-23.png)"}}></div>
            <div className="auto-container"> 
                <div className="filter-box">
                    <div className="box-inner">
                        <div className="clearfix">
                            
                            <div className="pull-left clearfix">
                                <div className="dropdown-outer">
                                    <div className="filter-dropdown">
                                        <span className="icon flaticon-filter-filled-tool-symbol"></span> Filter <span className="arrow fa fa-angle-down"></span>
                                         
                                        <div className="filter-categories">
                                            <div className="clearfix">
                                                 
                                                <div className="column">
                                                    <h6>Categories</h6>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-one"/>
                                                            <label htmlFor="radio-one">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Digital Marketing
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-two"/>
                                                            <label htmlFor="radio-two">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Business
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-three"/>
                                                            <label htmlFor="radio-three">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Social Media Marketing
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-four"/>
                                                            <label htmlFor="radio-four">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Design
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-five"/>
                                                            <label htmlFor="radio-five">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                User experience
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-six"/>
                                                            <label htmlFor="radio-six">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                User interface
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-seven"/>
                                                            <label htmlFor="radio-seven">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                IT & Software Courses
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                 
                                                <div className="column">
                                                    <h6>Instructor</h6>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-eight"/>
                                                            <label htmlFor="radio-eight">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Mahfuz Riad (15)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-nine"/>
                                                            <label htmlFor="radio-nine">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Siful islam
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-ten"/>
                                                            <label htmlFor="radio-ten">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Foqrul Munna (12)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-eleven"/>
                                                            <label htmlFor="radio-eleven">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Mahadi Shopnil (16)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twelve"/>
                                                            <label htmlFor="radio-twelve">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Instructor (8)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-thirteen"/>
                                                            <label htmlFor="radio-thirteen">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Alex Saim (04)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                 
                                                <div className="column">
                                                    <h6>Sort by</h6>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-fourteen"/>
                                                            <label htmlFor="radio-fourteen">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                New Courses
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-fifteen"/>
                                                            <label htmlFor="radio-fifteen">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Old Courses 
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-sixteen"/>
                                                            <label htmlFor="radio-sixteen">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Course 2019
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-seventeen"/>
                                                            <label htmlFor="radio-seventeen">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Course 2018
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-eighteen"/>
                                                            <label htmlFor="radio-eighteen">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Course 2017
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                 
                                                <div className="column">
                                                    <h6>Language</h6> 

                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-nineteen"/>
                                                            <label htmlFor="radio-nineteen">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                English (10)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twenty"/>
                                                            <label htmlFor="radio-twenty">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                French (4)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentyone"/>
                                                            <label htmlFor="radio-twentyone">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                German (5)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentytwo"/>
                                                            <label htmlFor="radio-twentytwo">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Japanese (4)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentythree"/>
                                                            <label htmlFor="radio-twentythree">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Russian (1)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentyfour"/>
                                                            <label htmlFor="radio-twentyfour">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Spanish (6)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                 
                                                <div className="column">
                                                    <h6>Price</h6> 
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentyfive"/>
                                                            <label htmlFor="radio-twentyfive">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                All (90)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentysix"/>
                                                            <label htmlFor="radio-twentysix">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Paid (70)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentyseven"/>
                                                            <label htmlFor="radio-twentyseven">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Free (12)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <h6>Level</h6> 
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentyeight"/>
                                                            <label htmlFor="radio-twentyeight">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Beginner (21)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-twentynine"/>
                                                            <label htmlFor="radio-twentynine">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Intermediate (10)
                                                            </label>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                                        <div className="select-box">
                                                            <input type="checkbox" name="payment-group" id="radio-thirty"/>
                                                            <label htmlFor="radio-thirty">
                                                                <span className="default-check"></span>
                                                                <span className="check-icon fa fa-check"></span>
                                                                Expert (4)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>				
                                </div> 
                            </div>
                            <div className="pull-right">
                                <div className="total-course">We found <span>{course.length}</span> courses available for you</div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div className="outer-container">

                <div className="row clearfix"> 

                    {course.map((element) => {  
                        let get_list_points = element.list_points;
                        return <div key={element.id} className="course-block style-two col-lg-3 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <div className="image">
                                    <Link to={`/course/${element.slug}`}><img src={element.image} alt={element.name} /></Link>
                                    <div className="tag">ENROL NOW</div>
                                </div>
                                <div className="lower-content">
                                    <h4><Link to={`/course/${element.slug}`}>{element.name}</Link></h4>
                                    <div className="uni-name">{element.created_by}</div>
                                    <div className="rating">
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star-o"></span>
                                        <strong>4.9</strong>
                                        <i>(70 Review)</i>
                                    </div>
                                    <div className="clearfix">
                                        <div className="price">₹ {element.discount_price} <span>₹ {element.price}</span></div> 
                                        <div className="hovers">{element.hours} total hours . All Levels</div>
                                    </div>
                                </div>
                                
                                <div className="overlay-content-box">
                                    <h4>{element.name}</h4>
                                    <div className="uni-name">{element.created_by}</div>
                                    <div className="rating">
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star-o"></span>
                                        <strong>4.9</strong>
                                        <i>(70 Review)</i>
                                    </div>
                                    <div className="clearfix">
                                        <div className="price">₹ {element.discount_price} <span>₹ {element.price}</span></div>
                                        <div className="hovers">{element.hours} total hours . All Levels</div>
                                    </div>
                                    <div className="text">{element.small_description}</div>
                                    {(get_list_points.length>0)?
                                    <ul className="lists"> 
                                        {get_list_points.map((listelement) => { 
                                            return <li key={listelement}>{listelement}</li>
                                        })} 
                                    </ul>
                                    :''}
                                     
                                    <div className="btns-box">
                                        <Link to={`/course/${element.slug}`} className="theme-btn enrol-btn">Enrol Now</Link>
                                        <Link to={`/course/${element.slug}`} className="theme-btn wishlist-btn">Details</Link>
                                    </div>
                                </div> 
                                
                            </div>
                        </div>
                    })}  
 
                </div>
                    
            </div> 

        </section>

        
        </>
    )
}

export default Courses